<template>
  <v-container>
    <h1>DISCLAIMERS</h1>

    <h2>WEBSITE DISCLAIMER</h2>
    The information provided by Pop Mystic LLC (“we,” “us”, or “our”) on
    https://www.popmystic.com (the “Site”) is for general informational purposes
    only. All information on the Site is provided in good faith, however we make
    no representation or warranty of any kind, express or implied, regarding the
    accuracy, adequacy, validity, reliability, availability or completeness of
    any information on the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY
    LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
    THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE.
    YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS
    SOLELY AT YOUR OWN RISK.
    <h2>EXTERNAL LINKS DISCLAIMER</h2>
    The Site may contain (or you may be sent through the Site) links to other
    websites or content belonging to or originating from third parties or links
    to websites and features in banners or other advertising. Such external
    links are not investigated, monitored, or checked for accuracy, adequacy,
    validity, reliability, availability or completeness by us. WE DO NOT
    WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR
    RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED
    THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER
    ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
    MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS
    OR SERVICES.
    <h2>AFFILIATES DISCLAIMER</h2>
    The Site may contain links to affiliate websites, and we receive an
    affiliate commission for any purchases made by you on the affiliate website
    using such links.

    <h2>TESTIMONIALS DISCLAIMER</h2>
    The Site may contain testimonials by users of our products and/or services.
    These testimonials reflect the real-life experiences and opinions of such
    users. However, the experiences are personal to those particular users, and
    may not necessarily be representative of all users of our products and/or
    services. We do not claim, and you should not assume, that all users will
    have the same experiences. YOUR INDIVIDUAL RESULTS MAY VARY. The
    testimonials on the Site are submitted in various forms such as text, audio
    and/or video, and are reviewed by us before being posted. They appear on the
    Site verbatim as given by the users, except for the correction of grammar or
    typing errors. Some testimonials may have been shortened for the sake of
    brevity where the full testimonial contained extraneous information not
    relevant to the general public. The views and opinions contained in the
    testimonials belong solely to the individual user and do not reflect our
    views and opinions. We are not affiliated with users who provide
    testimonials, and users are not paid or otherwise compensated for their
    testimonials. This disclaimer was created using Termly's Disclaimer
    Generator.
    <h2>FAIR USE DISCLAIMER</h2>
    This site is for educational purposes only. Pop Mystic does not own this
    content. All credits go to its rightful owner. Fair Use Copyright Disclaimer
    Under section 107 of the Copyright Act of 1976, allowance is made for “fair
    use” for purposes such as criticism , comment , news reporting, teaching,
    scholarship, education and research. Fair use is permitted by copyright
    statute that might otherwise be infringing. Fair Use Definition Fair use is
    a doctrine in United States copyright law that allows limited use of
    copyrighted material without requiring permission from the rights holders,
    such as commentary, criticism, news reporting , research, teaching, or
    scholarship. It provides for the legal, non-licensed citation or
    incorporation of copyrighted materials in another author’s work under a four
    factor balancing test.

    <h2>PRIVACY POLICY</h2>

    1. Introduction 1.1 Our website uses cookies. 1.2 Insofar as those cookies
    are not strictly necessary for the provision of popmystic.com, we will alert
    you to their use when you first visit our site and we assume your consent to
    our use of cookies if you continue to use the website. 2. Credit 2.1 This
    document was created using a template from Docular (https://seqlegal.com).
    3. About cookies 3.1 A cookie is a file containing an identifier (a string
    of letters and numbers) that is sent by a web server to a web browser and is
    stored by the browser. The identifier is then sent back to the server each
    time the browser requests a page from the server. 3.2 Cookies may be either
    "persistent" cookies or "session" cookies: a persistent cookie will be
    stored by a web browser and will remain valid until its set expiry date,
    unless deleted by the user before the expiry date; a session cookie, on the
    other hand, will expire at the end of the user session, when the web browser
    is closed. 4. Cookies that we use 4.1 We use cookies for the following
    purposes: (a) advertising - we use cookies [to help us to display
    advertisements that will be relevant to you (b) analysis - we use cookies
    [to help us to analyse the use and performance of our website and services
    5. Cookies used by our service providers 5.1 Our service providers use
    cookies and those cookies may be stored on your computer when you visit our
    website. 5.2 We use Google Analytics. Google Analytics gathers information
    about the use of our website by means of cookies. The information gathered
    is used to create reports about the use of our website. You can find out
    more about Google's use of information by visiting
    https://www.google.com/policies/privacy/partners/ and you can review
    Google's privacy policy at https://policies.google.com/privacy. 5.3 We
    publish Google AdSense advertisements on our website. The advertisements may
    be personalised to reflect your interests. To help determine your interests
    Google and its partners use cookies. The cookies are used to track your
    previous visits to our website and your visits to other websites. You can
    opt out of Google's personalized advertising by visiting
    https://www.google.com/settings/ads and you can opt out of third party
    cookies use for personalised advertising by visiting
    https://www.aboutads.info. You can review Google's privacy policy at
    https://policies.google.com/privacy. 6. Managing cookies 6.1 Most browsers
    allow you to refuse to accept cookies and to delete cookies. The methods for
    doing so vary from browser to browser, and from version to version. You can
    however obtain up-to-date information about blocking and deleting cookies
    via these links: (a) https://support.google.com/chrome/answer/95647
    (Chrome); (b)
    https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
    (Firefox); (c) https://help.opera.com/en/latest/security-and-privacy/
    (Opera); (d)
    https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
    (Internet Explorer); (e)
    https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
    (Safari); and (f)
    https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
    (Edge). 6.2 Blocking all cookies will have a negative impact upon the
    usability of many websites.
  </v-container>
</template>

<script>
export default {
  name: "Disclaimers",
};
</script>
